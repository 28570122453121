<template>
  <div>
  
    <table v-if="!loading" :id="table" class="table table-striped dt-responsive mb-3" :class="width ? 'table-width' :'' ">
      <thead>
        <tr>
          <th :class="width ? 'header-width' :'' ">No</th>
          <th v-for="(column, index) in columns" :key="index" class="w-250">
              {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in data" :key="rowIndex">
          <td>{{ (currentPage - 1) * localPageSize + rowIndex + 1 }}</td>
          <td v-for="(column, colIndex) in columns" :key="colIndex">
            <slot :name="`column-${column.data}`" :row="row" :rowIndex="rowIndex">
              {{ row[column.data] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else-if="loading" class="loading-indicator">
      Loading...
    </div>
    <div v-else class="no-data-indicator">
      No Data Available
    </div>

    <div v-if="!loading" class="pagination-wrapper">
      <div class="pagination-info">
        <span>{{ paginationInfo }}</span>
      </div>
      <div class="pagination-controls">
        <button
          class="btn btn-outline-primary"
          :disabled="currentPage === 1"
          @click="changePage(currentPage - 1)"
        >
          Previous
        </button>

        <button
          v-if="currentPage > 3"
          class="btn btn-outline-primary"
          @click="changePage(1)"
        >
          1
        </button>

        <span v-if="showEllipsisBefore" class="pagination-ellipsis">...</span>

        <button
          v-for="page in visiblePageNumbers"
          :key="page"
          class="btn btn-outline-primary"
          :class="{ 'active': currentPage === page }"
          @click="changePage(page)"
        >
          {{ page }}
        </button>

        <span v-if="showEllipsisAfter" class="pagination-ellipsis">...</span>

        <button
          v-if="currentPage < totalPages - 2"
          class="btn btn-outline-primary"
          @click="changePage(totalPages)"
        >
          {{ totalPages }}
        </button>

        <button
          class="btn btn-outline-primary"
          :disabled="currentPage * localPageSize >= totalRecords"
          @click="changePage(currentPage + 1)"
        >
          Next
        </button>
        
        <select v-model="localPageSize" @change="onPageSizeChange" class="form-select">
          <option v-for="size in pageSizes" :key="size" :value="size">{{ size }} per page</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    width: {
      type:Boolean,
      default: false
    }
  },

  data() {
    return {
      pageSizes: [ 2, 5, 10, 25, 50, 75, 100],
      localPageSize: this.pageSize,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalRecords / this.localPageSize);
    },
    paginationInfo() {
      const start = (this.currentPage - 1) * this.localPageSize + 1;
      const end = Math.min(this.currentPage * this.localPageSize, this.totalRecords);
      return `Showing ${start} to ${end} of ${this.totalRecords} entries`;
    },
    pageNumbers() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },
    visiblePageNumbers() {
      const delta = 2; // Number of pages to show before and after the current page
      const pages = [];
      const firstPage = 1;
      const lastPage = this.totalPages;

      // Add pages around the current page
      for (let page = Math.max(firstPage, this.currentPage - delta); page <= Math.min(lastPage, this.currentPage + delta); page++) {
        pages.push(page);
      }

      return pages;
    },
    showEllipsisBefore() {
      return this.currentPage > 4;
    },
    showEllipsisAfter() {
      return this.currentPage < this.totalPages - 3;
    },
  },

  watch: {
    data() {
      this.initializeDataTable();
    },
    currentPage() {
      this.updatePagination();
    },
    localPageSize(newSize) {
      this.updatePagination(newSize);
    },
  },

  methods: {
    initializeDataTable() {
  
      if (this.data.length === 0) return;

      if ($.fn.DataTable.isDataTable(`#${this.table}`)) {
        $(`#${this.table}`).DataTable().destroy();
      }
  
      $(`#${this.table}`).DataTable({
        data: this.data,
        columns: [
          { data: null, title: 'No', render: (data, type, row, meta) => meta.row + 1 },
          ...this.columns.map((column) => ({ data: column.data })),
        ],
        paging: false,
        searching: false,
        info: false,
        ordering: false,
        serverSide: true,
        processing: true,
        language: {
          emptyTable: "No data available in table",
          loadingRecords: "Loading...",
          zeroRecords: "No matching records found",
        },
        ajax: (data, callback) => {
          this.$emit('update:pagination', {
            currentPage: data.draw,
            pageSize: this.localPageSize,
          });
          callback({
            draw: data.draw,
            recordsTotal: this.totalRecords,
            recordsFiltered: this.totalRecords,
            data: this.data,
          });
        },
      });
    
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('update:pagination', {
          currentPage: page,
          pageSize: this.localPageSize,
        });
      }
    },
    onPageSizeChange() {
      this.localPageSize = parseInt(this.localPageSize, 10);
      this.$emit('update:pagination', {
        currentPage: 1,
        pageSize: this.localPageSize,
      });
    },

    updatePagination(size) {
      this.$emit('update:pagination', {
        currentPage: this.currentPage,
        pageSize: size || this.localPageSize,
      });
    },
  },
};
</script>

<style scoped>
.loading-indicator {
  text-align: center;
  font-size: 1.2rem;
  padding: 20px;
}

.no-data-indicator {
  text-align: center;
  font-size: 1.2rem;
  padding: 20px;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pagination-info {
  flex: 1;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-controls .form-select {
  margin-left: 10px;
}

.pagination-controls button.active {
  background-color: #007bff;
  color: white;
}

.pagination-ellipsis {
  margin: 0 5px;
}

.table-width {
  width: 2500px;
}

.header-width {
    widows: 100px;
}

.dataTables_wrapper .dataTables_paginate {
  display: none; /* Hide default DataTables pagination */
}
</style>
