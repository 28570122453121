import { exportExcel as exportSheet } from './excel-export-utlis'; // Adjust the import path accordingly

export const exportExcel = (apiData, columns, title) => {
  // Create headers
  const headers = ["No.", ...columns.map(col => col.title)];

  // Start with the headers in the sheet data
  const sheetData = [headers];

  // Add data rows
  apiData.forEach((item, index) => {
    const row = [
      index + 1, // The index column (1-based)
      ...columns.map(col => item[col.data])
    ];
    sheetData.push(row);
  });

  // Use the second utility function to export the sheet data
  exportSheet(sheetData, title);
};
