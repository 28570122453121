import { exportPdf as export_pdf } from './pdf-export-utlis'; // Adjust the import path accordingly

export const exportPdf = async (apiData, columns, title, pageWidth = 600) => {

    const sheetData = apiData.map((item, index) => {
                        let row = [];
                        row[0] = index + 1; // Assuming the first column is the index (1-based)
                        
                        columns.forEach((col, colIndex) => {
                        row[colIndex + 1] = item[col.data];
                        });
                        
                        return row;
                    });
                    
    const headers = ["No."];

    // Map over the columns array to extract the title of each column
    columns.forEach(col => {
        headers.push(col.title);
    });

    export_pdf(headers, sheetData, title, pageWidth);
};